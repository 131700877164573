var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isEqual as _isEqual } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { usePrevious } from '../../../../customHooks';
import { getBearingsConfig } from '../../../components/charts/chartsWrapper/components/shared/chartToolsButtons/Bearings/Bearings';
import { FftChartsStore } from '../../../store/charts/chartsContent/FftChartsStore';
import { InstallationPointStore } from '../../../store/charts/chartsContent/InstallationPointStore';
export var useBearings = function (state, chartRef, chartIdentifier, loader) {
    var installationPointBearings = FftChartsStore(function (state) { return state.installationPointBearings; });
    var _a = InstallationPointStore(function (state) { return ({
        currentSpeed: state.currentSpeed,
        installationPoint: state.installationPoint,
    }); }, shallow), currentSpeed = _a.currentSpeed, installationPoint = _a.installationPoint;
    var options = state.options, isVisibleBearingIds = state.isVisibleBearingIds;
    var prevInstallationPoint = usePrevious(installationPoint);
    var prevInstallationPointBearings = usePrevious(isVisibleBearingIds);
    useEffect(function () {
        var _a;
        if (loader || !((_a = options === null || options === void 0 ? void 0 : options.series) === null || _a === void 0 ? void 0 : _a.length)) {
            return;
        }
        var bearingsIsRemoved = false;
        isVisibleBearingIds.forEach(function (id) {
            id = +id.split('-')[0];
            var bearing = installationPointBearings.find(function (bearing) { return bearing.id === id; });
            if (!bearing || !bearing.id) {
                bearingsIsRemoved = true;
                return;
            }
        });
        if (prevInstallationPoint.id === installationPoint.id && !bearingsIsRemoved) {
            return;
        }
        updateBearings(chartRef, chartIdentifier, currentSpeed, installationPointBearings, isVisibleBearingIds);
    }, [installationPointBearings, currentSpeed]);
    useEffect(function () {
        if (!prevInstallationPointBearings || _isEqual(prevInstallationPointBearings, isVisibleBearingIds)) {
            return;
        }
        updateBearings(chartRef, chartIdentifier, currentSpeed, installationPointBearings, isVisibleBearingIds);
    }, [isVisibleBearingIds]);
};
var updateBearings = function (chartRef, chartIdentifier, currentSpeed, installationPointBearings, isVisibleBearingIds) {
    var otherPlotLines = (chartRef.current.chart.xAxis[0].plotLinesAndBands || [])
        .filter(function (plotLine) { return plotLine.options.type !== 'bearings'; })
        .map(function (plotLine) { return plotLine.options; });
    chartRef.current.chart.xAxis[0].update({
        plotLines: __spreadArray(__spreadArray([], otherPlotLines, true), getBearingsConfig(chartIdentifier, currentSpeed, installationPointBearings, isVisibleBearingIds), true),
    });
};
