import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useContext } from 'react';
import { FaInfoCircle, FaPlus, FaSpinner } from 'react-icons/fa';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { DateTimeLabelWithUTC } from '../../../widgets/common';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { onCustomBearingButton } from '../../features/bearings';
import { useBearingLogsQuery } from '../../services/bearings/useBearingLogsQuery';
import { useBearingsQuery } from '../../services/bearings/useBearingsQuery';
import { ChartFeaturesStore } from '../../store/charts/chartsContent/ChartFeaturesStore';
import { InstallationPointStore } from '../../store/charts/chartsContent/InstallationPointStore';
var BearingsTitle = function (_a) {
    var smallLoader = _a.smallLoader, selectedInstallationPointId = _a.selectedInstallationPointId, readonly = _a.readonly;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var installationPoint = InstallationPointStore(function (state) { return state.installationPoint; }, shallow);
    var setStatesChartFeaturesStore = ChartFeaturesStore(function (state) { return state.setStates; });
    var data = useBearingsQuery({
        installationPointId: selectedInstallationPointId || installationPoint.id,
        search: '',
        pagination: {
            page: 1,
            pageSize: 10,
            total: 0,
        },
        selfBearings: false,
        mfr: '',
    }).data;
    var logsData = useBearingLogsQuery(selectedInstallationPointId || installationPoint.id).data;
    var selected = (data === null || data === void 0 ? void 0 : data.selected) || [];
    return (_jsxs(_Fragment, { children: [(logsData === null || logsData === void 0 ? void 0 : logsData.list.length) > 0 ? (_jsxs(_Fragment, { children: [_jsx("span", { id: "bearings-logs", children: _jsx(FaInfoCircle, { className: "me-2 text-danger" }) }), _jsx(UncontrolledTooltip, { target: "bearings-logs", children: logsData === null || logsData === void 0 ? void 0 : logsData.list.map(function (log) {
                            var _a;
                            return (_jsxs("div", { children: ["- ", log.action, " ", log.data.length ? (_a = JSON.parse(log.data[0].data)) === null || _a === void 0 ? void 0 : _a.name : '', " bearing by", ' ', log.user_name, " at ", _jsx(DateTimeLabelWithUTC, { dateTime: log.created_at, hideUtc: true })] }, "bearing-log-".concat(log.id)));
                        }) })] })) : (_jsx(_Fragment, {})), "Manage bearings (selected ", selected.length, " / 10)", !readonly && (_jsxs(Button, { color: "primary", size: "sm", className: "ms-2", onClick: function () {
                    return onCustomBearingButton(selectedSystemType, installationPoint, setStatesChartFeaturesStore, {});
                }, children: [_jsx(FaPlus, {}), " Add Custom Bearing"] })), smallLoader ? _jsx(FaSpinner, { className: "ms-2 spinner-anim" }) : _jsx(_Fragment, {})] }));
};
export default memo(BearingsTitle);
