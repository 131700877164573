// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bearings-dropdown .dropdown-menu {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/charts/chartsWrapper/components/shared/chartToolsButtons/Bearings/index.scss"],"names":[],"mappings":"AACC;EACC,iBAAA;EACA,gBAAA;EACA,gBAAA;AAAF","sourcesContent":[".bearings-dropdown {\n\t& .dropdown-menu {\n\t\tmax-height: 500px;\n\t\toverflow: hidden;\n\t\toverflow-y: auto;\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
