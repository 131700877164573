// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aiPreAi-rate-ml .rate:hover svg {
  fill: #c1b621;
  opacity: 1;
}
.aiPreAi-rate-ml .rate span {
  cursor: pointer;
  font-size: 24px;
}
.aiPreAi-rate-ml .rate span svg {
  opacity: 0.4;
  fill: gray;
  transition: fill 0.3s, opacity 0.3s;
}
.aiPreAi-rate-ml .rate span.active svg {
  fill: #c1b621;
  opacity: 1;
}
.aiPreAi-rate-ml .rate span:hover ~ span svg {
  fill: gray;
  opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./../src/shared/components/preActionItem/RateMLDiagnosis/index.scss"],"names":[],"mappings":"AAEE;EACC,aAAA;EACA,UAAA;AADH;AAGE;EACC,eAAA;EACA,eAAA;AADH;AAEG;EACC,YAAA;EACA,UAAA;EACA,mCAAA;AAAJ;AAEG;EACC,aAAA;EACA,UAAA;AAAJ;AAEG;EACC,UAAA;EACA,YAAA;AAAJ","sourcesContent":[".aiPreAi-rate-ml {\n\t& .rate {\n\t\t&:hover svg {\n\t\t\tfill: #c1b621;\n\t\t\topacity: 1;\n\t\t}\n\t\tspan {\n\t\t\tcursor: pointer;\n\t\t\tfont-size: 24px;\n\t\t\tsvg {\n\t\t\t\topacity: 0.4;\n\t\t\t\tfill: gray;\n\t\t\t\ttransition: fill 0.3s, opacity 0.3s;\n\t\t\t}\n\t\t\t&.active svg {\n\t\t\t\tfill: #c1b621;\n\t\t\t\topacity: 1;\n\t\t\t}\n\t\t\t&:hover ~ span svg {\n\t\t\t\tfill: gray;\n\t\t\t\topacity: 0.4;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
