var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { cloneDeep as _cloneDeep, get as _get, sortedLastIndexBy as _sortedLastIndexBy, zip as _zip, } from 'lodash';
import moment from 'moment';
import { FFT_SERIES_COLORS, FREQUENCY_TYPES } from '../../constants';
import { FrequencyConverter } from '../components/charts/chartsWrapper/features/fftTwf/frequencyConverter';
import { getPointBetween, getUoms } from './chart';
import { getUserDateFormat } from './user';
export var EXPERIMENTAL_CUSTOMER_IDS = [377, 340, 304];
export var getMaximumTimestamps = function (installationPoint) { var _a; return EXPERIMENTAL_CUSTOMER_IDS.includes((_a = installationPoint.customer_id) !== null && _a !== void 0 ? _a : 0) ? 300 : 10; };
export var getFromTo = function (from, to) {
    from = Math.max(+from || 0, 0);
    to = Math.max(+to || Infinity, 0);
    if (from === to) {
        return [0, to || Infinity];
    }
    else if (from > to) {
        return [to, from];
    }
    return [from, to];
};
export var calculateWaterfallSeries = function (response, frequencyType, readingIds, readingType, chartIdentifier, installationPoint, from, to, currentSpeed, measure, personalSettingMeasure) {
    var _a;
    var series = [];
    var tickvals = [];
    var ticktext = [];
    var timestamps = [];
    var defaultColors = __spreadArray([], FFT_SERIES_COLORS, true);
    var yMax = 0;
    var xMax = 0;
    _a = getFromTo(from, to), from = _a[0], to = _a[1];
    var convert = function (y) {
        if (frequencyType !== FREQUENCY_TYPES.HZ) {
            return FrequencyConverter.fromHz(y, currentSpeed).toType(frequencyType).value;
        }
        return y;
    };
    from = convert(from);
    to = convert(to);
    readingIds.map(function (readingId, seriaIndex) {
        var readingData = _get(response, [readingType.chartGroup, readingType.axisId, installationPoint.id, readingId], []), color = defaultColors.shift();
        if (readingData &&
            readingData.data &&
            readingData.data.length &&
            !timestamps.includes(readingData.stored_timestamp)) {
            var seriesDays = '-' +
                Math.floor(moment.duration(moment.utc().diff(moment.utc(readingData.stored_timestamp))).asDays()) +
                'd';
            var seriesName = moment
                .utc(readingData.stored_timestamp)
                //@ts-ignore
                .format(getUserDateFormat(personalSettingMeasure));
            var x_1 = [];
            var y_1 = [];
            var z_1 = [];
            timestamps.push(readingData.stored_timestamp);
            readingData.data.forEach(function (readingItem, index) {
                var _a, _b;
                var freq = readingItem[0];
                var amp = readingItem[1];
                freq = convert(freq);
                xMax = Math.max(xMax, freq);
                if (freq < +from) {
                    var nextReading = readingData.data[index + 1];
                    if (nextReading) {
                        // eslint-disable-next-line prefer-const
                        var nextFreq = nextReading[0], nextAmp = nextReading[1];
                        nextFreq = convert(nextFreq);
                        if (nextFreq > from) {
                            _a = getPointBetween([freq, amp], [nextFreq, nextAmp], +from), freq = _a[0], amp = _a[1];
                        }
                        else {
                            return;
                        }
                    }
                    else {
                        return;
                    }
                }
                if (freq > +to) {
                    var prevReading = readingData.data[index - 1];
                    if (prevReading) {
                        // eslint-disable-next-line prefer-const
                        var prevFreq = prevReading[0], prevAmp = prevReading[1];
                        prevFreq = convert(prevFreq);
                        if (prevFreq < to) {
                            _b = getPointBetween([prevFreq, prevAmp], [freq, amp], +to), freq = _b[0], amp = _b[1];
                        }
                        else {
                            return;
                        }
                    }
                    else {
                        return;
                    }
                }
                yMax = Math.max(yMax, amp);
                x_1.push(seriaIndex);
                y_1.push(freq);
                z_1.push(amp);
            });
            tickvals.push(seriaIndex);
            ticktext.push(seriesDays);
            series.push({
                x: x_1,
                y: y_1,
                z: z_1,
                type: 'scatter3d',
                mode: 'lines',
                line: { color: color },
                marker: {
                    symbol: 'diamond',
                },
                name: "".concat(seriesDays, " - ").concat(seriesName),
                meta: {
                    categoryName: seriesName,
                },
                // templating reference: https://plotly.com/javascript/reference/scatter3d/#scatter3d-hovertemplate
                hovertemplate: [
                    "Frequency: <b>%{y:.2f}".concat(frequencyType === FREQUENCY_TYPES.ORDERS ? 'x' : '', "</b> ").concat(frequencyType),
                    "<span style=\"color:".concat(color, ";\">%{data.name}: </span> %{z:.").concat(readingType.precision, "f} ").concat(getUoms(chartIdentifier, measure, personalSettingMeasure)),
                    '<extra></extra>',
                ].join('<br>'),
            });
        }
    });
    var resultTickvals = [];
    var resultTicktext = [];
    var maxTick = 30;
    if (tickvals.length >= maxTick) {
        for (var i = 0; i < maxTick; i++) {
            var index = Math.floor((i * (tickvals.length - 1)) / (maxTick - 1));
            resultTickvals.push(index);
            resultTicktext.push(ticktext[index]);
        }
    }
    else {
        resultTickvals = tickvals;
        resultTicktext = ticktext;
    }
    return [series, resultTickvals, resultTicktext, xMax, yMax, timestamps];
};
export var calculateReadings = function (selectedType, fftTimestamps, readingType, maxDate, currentDate, daysForPerDaysType, readingsLimit, prevstate) {
    if (selectedType === 'latest') {
        return getLastReadingsList(fftTimestamps, readingType, maxDate, readingsLimit);
    }
    if (selectedType === 'per_days' && daysForPerDaysType) {
        var currentTimestamp_1 = moment(currentDate).startOf('day').unix();
        var readingIds_1 = [];
        Object.keys(fftTimestamps[readingType.axisId])
            .reverse()
            .forEach(function (_, index) {
            var _a, _b;
            if (readingIds_1.length === readingsLimit) {
                return;
            }
            var items = (Object.values(fftTimestamps[readingType.axisId]).reverse()[index] ||
                []);
            for (var i = items.length - 1; i >= 0; i--) {
                if (moment((_a = items[i]) === null || _a === void 0 ? void 0 : _a.timestamp)
                    .startOf('day')
                    .unix() <= currentTimestamp_1 &&
                    readingIds_1.length < readingsLimit) {
                    readingIds_1.push(+((_b = items[i]) === null || _b === void 0 ? void 0 : _b.readingFFT_id));
                    currentTimestamp_1 -= 86400 * daysForPerDaysType;
                }
            }
        });
        return readingIds_1.reverse();
    }
    if (selectedType === 'selected' && prevstate) {
        return prevstate;
    }
    return [];
};
export var getLastReadingsList = function (fftTimestamps, readingType, maxDate, readingsLimit) {
    var date = Object.keys(fftTimestamps[readingType.axisId]).filter(function (date) { return !maxDate || date <= maxDate; }), newDate = date.reverse().slice(0, readingsLimit);
    var readingsTimestamps = [];
    var readingsList = [];
    newDate.map(function (dateIndex) {
        // Timestamps queue is changed every time so have to use cloneDeep
        var newTimestamps = _cloneDeep(fftTimestamps[readingType.axisId][dateIndex]);
        newTimestamps.reverse().map(function (reading) {
            if (!readingsTimestamps.includes(reading.timestamp)) {
                readingsTimestamps.unshift(reading.timestamp);
                readingsList.unshift(reading.readingFFT_id);
            }
        });
    });
    if (readingsList.length > readingsLimit) {
        readingsList = readingsList.slice(-readingsLimit);
    }
    return readingsList;
};
export var getTrendSlice = function (trendFrequency, maxAmplitude, readingsList) {
    trendFrequency = parseFloat(trendFrequency);
    var readingsCount = Object.keys(readingsList).length;
    var x = readingsCount === 1
        ? [
            [-0.5, 0.5],
            [-0.5, 0.5],
        ]
        : [
            [0, readingsCount - 1],
            [0, readingsCount - 1],
        ];
    return {
        x: x,
        y: [
            [trendFrequency, trendFrequency],
            [trendFrequency, trendFrequency],
        ],
        z: [
            [0, 0],
            [maxAmplitude, maxAmplitude],
        ],
        hoverinfo: 'skip',
        showlegend: false,
        name: 'slice',
        type: 'surface',
        opacity: 0.5,
        surfacecolor: [
            [0, 0],
            [0, 0],
        ],
        showscale: false,
    };
};
export var getSeriesY = function (series, x) {
    x = parseFloat(x);
    var data = _zip(series.y, series.z);
    var lastPoint = data[data.length - 1];
    if (!lastPoint || lastPoint[0] < x) {
        return null;
    }
    else if (lastPoint[0] == x) {
        return lastPoint[1];
    }
    var firstPoint = data[0];
    if (firstPoint[0] > x) {
        return null;
    }
    else if (firstPoint[0] == x) {
        return firstPoint[1];
    }
    var nextPointIndex = _sortedLastIndexBy(data, { 0: x }, function (point) { return point[0]; });
    var nextPoint = data[nextPointIndex];
    if (+x === nextPoint[0]) {
        return nextPoint[1];
    }
    var prevPointIndex = nextPointIndex - 1;
    var prevPoint = data[prevPointIndex];
    if (+x === prevPoint[0]) {
        return prevPoint[1];
    }
    var point = getPointBetween(prevPoint, nextPoint, x);
    return point[1];
};
