import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { size as _size } from 'lodash';
import React, { memo, useContext } from 'react';
import { ACTION_LIST, SYSTEM_TYPE_REMASTERED } from '../../../../../constants';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { useZoomingImg } from '../../../../hooks/zoomingImg/useZoomingImg';
import { selectIsOnlyInternalComments } from '../../../../store/AIPreAi/useActionItemViewStore';
import Comment from '../Comment';
var CommentsList = function (_a) {
    var comments = _a.comments, userImage = _a.userImage, isViewMode = _a.isViewMode, isTroubleshoot = _a.isTroubleshoot, commentDeleteMutate = _a.commentDeleteMutate, internalCommentDeleteMutate = _a.internalCommentDeleteMutate, internalCommentUpdateIsLoading = _a.internalCommentUpdateIsLoading, internalCommentDeleteIsLoading = _a.internalCommentDeleteIsLoading, deletePreCommentMutate = _a.deletePreCommentMutate, confirmPreCommentMutate = _a.confirmPreCommentMutate, confirmPreCommentIsLoading = _a.confirmPreCommentIsLoading, deletePreCommentIsLoading = _a.deletePreCommentIsLoading, updatePreCommentIsLoading = _a.updatePreCommentIsLoading, commentDeleteIsLoading = _a.commentDeleteIsLoading, commentUpdateIsLoading = _a.commentUpdateIsLoading, actionListNoActionsTypes = _a.actionListNoActionsTypes, actionListNoActionsGroups = _a.actionListNoActionsGroups, actionListReportsSeverity = _a.actionListReportsSeverity, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var completionReportComment = null;
    var completionReportCommentNotExist = true;
    if (selectedSystemType === SYSTEM_TYPE_REMASTERED) {
        // @ts-ignore
        comments = comments.map(function (comment) {
            if (comment.type === ACTION_LIST.COMMENT_TYPE_COMPLETION_REPORT && completionReportCommentNotExist) {
                completionReportComment = comment;
                completionReportCommentNotExist = false;
                return {};
            }
            if (comment.comment === 'The reaction status changed to "Complete the Inspection"') {
                return {};
            }
            if (completionReportComment !== null && +comment.type === ACTION_LIST.COMMENT_TYPE_SYSTEM) {
                comment['linkedComment'] = completionReportComment;
                completionReportComment = null;
                completionReportCommentNotExist = true;
            }
            return comment;
        });
    }
    else {
        // @ts-ignore
        comments = comments.map(function (comment, index) {
            var _a, _b;
            if (((_a = comments[index + 1]) === null || _a === void 0 ? void 0 : _a.type) === ACTION_LIST.COMMENT_TYPE_COMPLETION_REPORT) {
                completionReportComment = comments[index + 1];
                completionReportCommentNotExist = false;
            }
            if (((_b = comments[index - 1]) === null || _b === void 0 ? void 0 : _b.type) === ACTION_LIST.COMMENT_TYPE_COMPLETION_REPORT) {
                completionReportComment = comments[index - 1];
                completionReportCommentNotExist = false;
            }
            if (completionReportComment !== null && +comment.type === ACTION_LIST.COMMENT_TYPE_SYSTEM) {
                comment['linkedComment'] = completionReportComment;
                completionReportComment = null;
            }
            return comment;
        });
    }
    comments = comments.filter(function (comment) { return _size(comment); });
    useZoomingImg('.zoomImg-comments img', [comments]);
    var isOnlyInternalComments = selectIsOnlyInternalComments();
    return (_jsxs("div", { className: "activity-log-comments-list", children: [comments.map(function (comment) { return (_jsx(Comment, { comment: comment, userImage: userImage, isViewMode: isViewMode, isTroubleshoot: isTroubleshoot, deletePreCommentMutate: deletePreCommentMutate, internalCommentDeleteMutate: internalCommentDeleteMutate, internalCommentUpdateIsLoading: internalCommentUpdateIsLoading, internalCommentDeleteIsLoading: internalCommentDeleteIsLoading, confirmPreCommentMutate: confirmPreCommentMutate, confirmPreCommentIsLoading: confirmPreCommentIsLoading, deletePreCommentIsLoading: deletePreCommentIsLoading, updatePreCommentIsLoading: updatePreCommentIsLoading, commentDeleteMutate: commentDeleteMutate, commentDeleteIsLoading: commentDeleteIsLoading, commentUpdateIsLoading: commentUpdateIsLoading, actionListNoActionsTypes: actionListNoActionsTypes, actionListNoActionsGroups: actionListNoActionsGroups, actionListReportsSeverity: actionListReportsSeverity, storage: storage }, comment.id)); }), comments.some(function (comment) { return comment.type === ACTION_LIST.COMMENT_TYPE_INTERNAL; }) ? (_jsx(_Fragment, {})) : isOnlyInternalComments ? (_jsx("div", { className: "text-center mt-4 fz-18", children: "Unable to find internal comments" })) : (_jsx(_Fragment, {}))] }));
};
export default memo(CommentsList);
