import { useQuery } from '@tanstack/react-query';
import Api from '../../../api/api';
import { AVATAR_AMAZON, PRE_ACTION_LIST } from '../../../constants';
import User from '../../../shared/helper/user';
import { selectMeasure } from '../../../shared/store/global/useGlobalStore';
export var preActionItemKey = function (preActionItemId) { return ['preActionItem', +preActionItemId]; };
var preActionItemFn = function (_a) {
    var queryKey = _a.queryKey;
    return Api.get("amazon-analytic/pre-action-list/".concat(queryKey[1]), {
        query: {},
    });
};
export var usePreActionItemQuery = function (_a) {
    var preActionItemId = _a.preActionItemId;
    var measure = selectMeasure();
    return useQuery({
        queryKey: preActionItemKey(preActionItemId),
        queryFn: preActionItemFn,
        select: function (_a) {
            var _b, _c, _d, _e, _f, _g, _h, _j, _k;
            var data = _a.data, photos = _a.photos, tags = _a.tags;
            var state = +(data === null || data === void 0 ? void 0 : data.status) || null, installationPointSensor = ((_b = data === null || data === void 0 ? void 0 : data.installationPoint) === null || _b === void 0 ? void 0 : _b.sensor) || null, isNewPreAi = state === PRE_ACTION_LIST.STATUS_NEW, installationPointName = (((_c = data === null || data === void 0 ? void 0 : data.installationPoint) === null || _c === void 0 ? void 0 : _c.component) || '') + ' - ' + (((_d = data === null || data === void 0 ? void 0 : data.installationPoint) === null || _d === void 0 ? void 0 : _d.point_name) || '');
            return {
                state: state,
                installationPointName: installationPointName,
                installationPointIdsOnEquipment: (data === null || data === void 0 ? void 0 : data.installationPointIdsOnEquipment) || [],
                installationPointId: ((_e = data === null || data === void 0 ? void 0 : data.installationPoint) === null || _e === void 0 ? void 0 : _e.id) || null,
                sensorId: (data === null || data === void 0 ? void 0 : data.sensor_id) || null,
                sensorPartNumber: (data === null || data === void 0 ? void 0 : data.sensorPartNumber) || '---',
                isDeactivated: !!installationPointSensor && !!(data === null || data === void 0 ? void 0 : data.is_deleted),
                title: (data === null || data === void 0 ? void 0 : data.title) || null,
                status: {
                    name: (_f = PRE_ACTION_LIST.STATUS_NAMES[state]) !== null && _f !== void 0 ? _f : '',
                    color: (_g = PRE_ACTION_LIST.STATUS_COLORS[state]) !== null && _g !== void 0 ? _g : '',
                },
                urgency: (data === null || data === void 0 ? void 0 : data.urgency) || null,
                isReplaceEquipment: !!(data === null || data === void 0 ? void 0 : data.is_replace_equipment),
                replacementType: (data === null || data === void 0 ? void 0 : data.replacement_type) || null,
                isShowEdit: isNewPreAi && User.can('managePreAi'),
                id: (data === null || data === void 0 ? void 0 : data.id) || null,
                isBot: !!(data === null || data === void 0 ? void 0 : data.is_bot),
                updatedAt: (data === null || data === void 0 ? void 0 : data.updated_at) || null,
                updatedBy: null,
                timeCreated: (data === null || data === void 0 ? void 0 : data.created_at) || null,
                createdBy: (data === null || data === void 0 ? void 0 : data.user_name) || null,
                comments: (data === null || data === void 0 ? void 0 : data.comments) || [],
                userImage: AVATAR_AMAZON,
                facility: (data === null || data === void 0 ? void 0 : data.facility) || null,
                facilityMeasure: ((_h = data === null || data === void 0 ? void 0 : data.facility) === null || _h === void 0 ? void 0 : _h.measurement_units) || measure,
                isViewMode: !(!+(data === null || data === void 0 ? void 0 : data.action_item_id) && isNewPreAi),
                existingActionItems: (data === null || data === void 0 ? void 0 : data.existingActionItems) || [],
                installationPointSensorId: ((_j = data === null || data === void 0 ? void 0 : data.installationPoint) === null || _j === void 0 ? void 0 : _j.sensor_id) || null,
                installationPoint: (data === null || data === void 0 ? void 0 : data.installationPoint) || null,
                approximateDate: (data === null || data === void 0 ? void 0 : data.approximate_date) || null,
                sla: (data === null || data === void 0 ? void 0 : data.sla) || null,
                serial: (data === null || data === void 0 ? void 0 : data.serial) || null,
                equipmentType: (data === null || data === void 0 ? void 0 : data.equipment_type) || null,
                activeActionList: (data === null || data === void 0 ? void 0 : data.activeActionList) || null,
                actionUserName: (data === null || data === void 0 ? void 0 : data.action_user_name) || '---',
                description: (data === null || data === void 0 ? void 0 : data.description) || null,
                declineReason: (data === null || data === void 0 ? void 0 : data.decline_reason) || null,
                preActionListTags: (data === null || data === void 0 ? void 0 : data.preActionListTags) || null,
                analystLog: (_k = data === null || data === void 0 ? void 0 : data.analystLog) !== null && _k !== void 0 ? _k : [],
                photos: photos,
                isNewPreAi: isNewPreAi,
                tags: tags,
                severityIncrease: (data === null || data === void 0 ? void 0 : data.severityIncrease) || [],
            };
        },
    });
};
