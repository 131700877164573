import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { FaPencilAlt, FaPlus, FaTimes, FaTrash } from 'react-icons/fa';
import { Badge, Button, ButtonGroup, Input, Label, UncontrolledTooltip } from 'reactstrap';
import { DateTimeLabelWithUTC } from '../../../widgets/common';
export var config = {
    config: {
        columns: [
            {
                name: 'is_custom',
                title: '',
                style: {
                    width: '60px',
                },
                sortable: false,
                component: function (row, _, filtersData) {
                    var _a, _b;
                    var log = (_b = (_a = filtersData.logsData) === null || _a === void 0 ? void 0 : _a.list) === null || _b === void 0 ? void 0 : _b.find(function (item) { return +item.entity_id === +row.id; });
                    return row.is_custom ? (_jsxs(_Fragment, { children: [_jsx(Badge, { id: "custom-bearing-".concat(row.id), className: "me-2", pill: true, color: "warning", size: "sm", children: "Custom" }), log && log.id ? (_jsxs(UncontrolledTooltip, { target: "custom-bearing-".concat(row.id), children: ["Custom bearing by ", log.user_name, " at", ' ', _jsx(DateTimeLabelWithUTC, { dateTime: log.created_at, hideUtc: true })] })) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}));
                },
            },
            {
                name: 'mfr',
                title: 'Mfr',
                sortable: false,
                component: function (row) {
                    return row.selected ? _jsx("b", { children: row.mfr }) : row.mfr;
                },
            },
            {
                name: 'name',
                title: 'Bearing ID',
                sortable: false,
                component: function (row) {
                    return row.selected ? _jsx("b", { children: row.name }) : row.name;
                },
            },
            {
                name: 'balls_rollers',
                title: '#Balls/Rollers',
                sortable: false,
                component: function (row) {
                    return row.selected ? _jsx("b", { children: row.balls_rollers }) : row.balls_rollers;
                },
            },
            {
                name: 'plot_lines',
                title: 'Plot Lines',
                sortable: false,
                component: function (row, callbacks) {
                    if (!row.selected) {
                        return _jsx(React.Fragment, {});
                    }
                    return (_jsx(Input, { type: "select", name: "plot_lines", className: "select-sm", defaultValue: +row.selected.plot_lines_count, onChange: function (ev) {
                            return callbacks.onPlotLinesCountChange(row.id, ev.target.value);
                        }, children: Array(100)
                            .fill(null)
                            .map(function (_, i) { return (_jsxs("option", { value: i + 1, children: ["Show ", i + 1] }, "bearing_modal_plot_lines_count".concat(i + 1))); }) }));
                },
            },
            {
                name: 'action',
                title: 'Action',
                style: {
                    width: '20%',
                },
                sortable: false,
                component: function (row, callbacks, filtersData) {
                    if (filtersData.readonly) {
                        return _jsx(_Fragment, {});
                    }
                    return (_jsxs(ButtonGroup, { className: "ms-2 d-flex justify-content-start w-100", children: [row.selected ? (_jsxs(Button, { style: { flex: 'none' }, color: "warning", size: "sm", onClick: function () { return callbacks.onRemove(row.id); }, children: [_jsx(FaTimes, {}), " Clear"] })) : (_jsxs(Button, { style: { flex: 'none' }, disabled: filtersData.selectedCount >= 10, color: "primary", size: "sm", onClick: function () { return callbacks.onAdd(row.id); }, children: [_jsx(FaPlus, {}), " Add"] })), row.is_custom ? (_jsxs(_Fragment, { children: [_jsxs(Button, { style: { flex: 'none' }, color: "danger", size: "sm", onClick: function () { return callbacks.onDelete(row.id); }, children: [_jsx(FaTrash, {}), " Delete"] }), _jsxs(Button, { style: { flex: 'none' }, color: "primary", size: "sm", onClick: function () { return callbacks.onEdit(row); }, children: [_jsx(FaPencilAlt, {}), " Edit"] })] })) : (_jsx(_Fragment, {}))] }));
                },
            },
        ],
        data: [],
        filtersData: {
            selectedCount: 0,
            logsData: { list: [] },
            mfrsData: [],
        },
        filters: function (callbacks, filtersData) {
            var _a;
            return (_jsxs(_Fragment, { children: [_jsxs(Label, { check: true, className: "ms-4", children: [_jsx(Input, { className: "position-relative me-2", type: "checkbox", onChange: callbacks.onSelfBearings }), _jsx("span", { children: "Custom Bearings" })] }), _jsxs(Input, { type: "select", name: "select", className: "select-sm w-auto mb-2 d-inline-block ms-4", onChange: function (ev) { return callbacks.setMfr(ev.target.value); }, children: [_jsx("option", { value: "", children: "All manufacturers" }), ((_a = filtersData.mfrsData) === null || _a === void 0 ? void 0 : _a.length) ? (_jsx(_Fragment, { children: filtersData.mfrsData.map(function (mfr) { return (_jsx("option", { value: mfr, children: mfr }, "mfr-filter-".concat(mfr))); }) })) : (_jsx(_Fragment, {}))] })] }));
        },
        loader: true,
    },
};
