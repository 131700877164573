var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { flatten as _flatten, get as _get, size as _size, sortBy as _sortBy, uniq as _uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FaArrowRight, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { shallow } from 'zustand/shallow';
import { READING_TYPES } from '../../../../../../../constants';
import { FftChartsStore } from '../../../../../../store/charts/chartsContent/FftChartsStore';
import './index.scss';
var BatchTimestampsCalendar = function (_a) {
    var chartIdentifier = _a.chartIdentifier, onApply = _a.onApply, _b = _a.inititalTimestamps, inititalTimestamps = _b === void 0 ? [] : _b, variant = _a.variant, readingsLimit = _a.readingsLimit;
    var _c = useState(null), startDate = _c[0], setStartDate = _c[1];
    var _d = useState(null), endDate = _d[0], setEndDate = _d[1];
    var getTime = function (date) {
        return _get(date.split(' '), 1);
    };
    var getDate = function (date) {
        return _get(date.split(' '), 0);
    };
    var _e = FftChartsStore(function (state) { return ({
        data: state.data,
        fftTimestampDates: state.fftTimestampDates,
    }); }, shallow), data = _e.data, fftTimestampDates = _e.fftTimestampDates;
    var _f = useState(inititalTimestamps), timestamps = _f[0], setTimestamps = _f[1];
    var _g = useState(false), isOpen = _g[0], setIsOpen = _g[1];
    var _h = useState(false), isTimeDropdownOpen = _h[0], setIsTimeDropdownOpen = _h[1];
    var readingType = READING_TYPES[chartIdentifier];
    var _j = useState(_get(data.fftTimestamps[readingType.axisId], [
        getDate(_get(fftTimestampDates, chartIdentifier)),
        0,
        'timestamp',
    ])), chosenDate = _j[0], setChosenDate = _j[1];
    var _k = useState(getTime(chosenDate)), selectedTime = _k[0], setSelectedTime = _k[1];
    var axisId = READING_TYPES[chartIdentifier].axisId;
    var availableDates = data.fftTimestamps[axisId];
    var times = _uniq(_get(availableDates, getDate(chosenDate), []).map(function (item) {
        return getTime(item.timestamp);
    }));
    useEffect(function () {
        setTimestamps(inititalTimestamps);
    }, [inititalTimestamps]);
    useEffect(function () {
        setSelectedTime(getTime(chosenDate));
    }, [chosenDate]);
    var onRemoveTimestamp = function (removedTimestamp) {
        setTimestamps(timestamps.filter(function (timestamp) { return timestamp !== removedTimestamp; }));
    };
    var onSelectTimestamp = function (selectedTimestamp) {
        if (timestamps.length === readingsLimit) {
            return toast.error('The maximum number of readings has been reached', {
                icon: _jsx(FaTimes, { style: { fill: '#af2929' } }),
            });
        }
        if (timestamps.includes(selectedTimestamp)) {
            return toast.error('Timestamp already exist', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
        }
        setTimestamps(_sortBy(__spreadArray(__spreadArray([], timestamps, true), [selectedTimestamp], false)));
    };
    var onChange = function (dates) {
        if (Array.isArray(dates)) {
            var start = dates[0], end = dates[1];
            setStartDate(start);
            setEndDate(end);
            setTimestamps([]);
            if (!start || !end) {
                return;
            }
            var startDate_1 = _get(availableDates, [moment(start).format('YYYY-MM-DD'), 0, 'timestamp']);
            var endDate_1 = _get(availableDates, [moment(end).format('YYYY-MM-DD'), 0, 'timestamp']);
            var selectedArr = _flatten(Object.values(availableDates).filter(function (value) {
                return moment(startDate_1).unix() <= moment(value[0].timestamp).unix() &&
                    moment(endDate_1).unix() >= moment(value[0].timestamp).unix();
            })).map(function (el) { return el.timestamp; });
            setTimestamps(_sortBy(__spreadArray(__spreadArray([], timestamps, true), selectedArr, true)));
            setChosenDate(_get(data.fftTimestamps[readingType.axisId], [
                getDate(_get(fftTimestampDates, chartIdentifier)),
                0,
                'timestamp',
            ]));
        }
        else {
            setChosenDate(_get(availableDates, [moment(dates).format('YYYY-MM-DD'), 0, 'timestamp']));
            setStartDate(null);
            setEndDate(null);
        }
    };
    return (_jsxs(Dropdown, { size: "sm", isOpen: isOpen, toggle: function () { }, children: [_jsx(DropdownToggle, { caret: true, outline: true, className: "calendar-dropdown btn-white", onClick: function () { return setIsOpen(!isOpen); }, children: "Select Dates" }), _jsx(DropdownMenu, { className: "p-0 mt-2", children: _jsxs("div", { className: "batch-timestamps-calendar", children: [_jsxs("div", { children: [_jsx(DatePicker, { value: chosenDate, selected: new Date(chosenDate), onChange: onChange, filterDate: function (date) { return _size(_get(availableDates, moment(date).format('YYYY-MM-DD'))); }, highlightDates: timestamps.map(function (timestamp) { return new Date(timestamp); }), inline: true, startDate: startDate, endDate: endDate, selectsRange: variant === 'multi' }), _jsxs("div", { className: "batch-timestamps-calendar-time_and_apply", children: [variant === 'single' && (_jsxs(Dropdown, { size: "sm", isOpen: isTimeDropdownOpen, toggle: function () { return setIsTimeDropdownOpen(!isTimeDropdownOpen); }, children: [_jsx(DropdownToggle, { caret: true, className: "btn-default", disabled: (times || []).length < 2, children: selectedTime || '-' }), _jsx(DropdownMenu, { className: (times || []).length > 8 ? 'custom-scroll' : '', children: (times || []).map(function (time) { return (_jsx(DropdownItem, { onClick: function () { return setSelectedTime(time); }, disabled: false, children: time }, time)); }) })] })), _jsxs(ButtonGroup, { children: [variant === 'single' && (_jsxs(Button, { color: "info", size: "sm", onClick: function () { return onSelectTimestamp("".concat(getDate(chosenDate), " ").concat(selectedTime)); }, disabled: timestamps.includes("".concat(getDate(chosenDate), " ").concat(selectedTime)) ||
                                                        timestamps.length === readingsLimit, children: [_jsx(FaArrowRight, {}), " Select"] })), _jsx(Button, { color: "success", size: "sm", onClick: function () {
                                                        onApply(timestamps);
                                                        setIsOpen(false);
                                                    }, children: "Apply" })] })] })] }), _jsxs("div", { children: [_jsx(Button, { className: "batch-timestamps-calendar-close", outline: true, onClick: function () { return setIsOpen(false); }, children: _jsx(FaTimes, {}) }), _jsxs("div", { className: "selected-date-list", children: [timestamps.map(function (timestamp, index) { return (_jsxs("div", { className: "selected-date", children: [_jsx("span", { className: "selected-date-timestamp", children: timestamp }), _jsx("span", { className: "selected-date-remove-btn", onClick: function () { return onRemoveTimestamp(timestamp); }, children: _jsx(FaTimes, {}) })] }, "".concat(timestamp, " ").concat(index))); }), timestamps.length === readingsLimit && (_jsx("div", { className: "text-danger", children: "The maximum number of readings has been reached." }))] })] })] }) })] }));
};
export default BatchTimestampsCalendar;
