import { keepPreviousData, useQueries } from '@tanstack/react-query';
import { chunk as _chunk, merge as _merge } from 'lodash';
import { useContext } from 'react';
import Api from '../../../api/api';
import { SystemTypeContext } from '../../context/SystemTypeContext';
import { getCoreUrl } from '../../helper/commonHelper';
export var fftBatchKey = function (installationPointId, readingsList, axisID, highpass, interpolationDisabled, selectedFacilityMetric, personalSettingMeasure, isHFDvue, readingType) { return [
    'fftWaterfall',
    installationPointId,
    readingsList,
    {
        axisID: axisID,
        highpass: highpass,
        interpolationDisabled: interpolationDisabled,
        selectedFacilityMetric: selectedFacilityMetric,
        personalSettingMeasure: personalSettingMeasure,
        isHFDvue: isHFDvue,
        readingType: readingType,
    },
]; };
var fftBatchFn = function (coreLink, queryKey) {
    var _a, _b, _c, _d, _e, _f;
    return Api.get("".concat(coreLink, "/charts/get-fft-data-batch"), {
        query: {
            duplicated: true,
            installationPointID: queryKey[1],
            readingsList: queryKey[2],
            axisID: (_a = queryKey[3]) === null || _a === void 0 ? void 0 : _a.axisID,
            highpass: (_b = queryKey[3]) === null || _b === void 0 ? void 0 : _b.highpass,
            interpolationDisabled: (_c = queryKey[3]) === null || _c === void 0 ? void 0 : _c.interpolationDisabled,
            isHFDvue: (_d = queryKey[3]) === null || _d === void 0 ? void 0 : _d.isHFDvue,
            selected_facility_metric: (_e = queryKey[3]) === null || _e === void 0 ? void 0 : _e.selectedFacilityMetric,
            readingType: (_f = queryKey[3]) === null || _f === void 0 ? void 0 : _f.readingType,
        },
    });
};
export var useFftBatchQuery = function (_a) {
    var installationPointId = _a.installationPointId, readingsList = _a.readingsList, axisID = _a.axisID, highpass = _a.highpass, interpolationDisabled = _a.interpolationDisabled, selectedFacilityMetric = _a.selectedFacilityMetric, personalSettingMeasure = _a.personalSettingMeasure, isHFDvue = _a.isHFDvue, _b = _a.readingType, readingType = _b === void 0 ? null : _b;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var results = useQueries({
        queries: _chunk(readingsList, 10).map(function (chunkReadingsList) { return ({
            // eslint-disable-next-line @tanstack/query/exhaustive-deps
            queryKey: fftBatchKey(installationPointId, chunkReadingsList, axisID, highpass, interpolationDisabled, selectedFacilityMetric, personalSettingMeasure, isHFDvue, readingType),
            queryFn: function (_a) {
                var queryKey = _a.queryKey;
                return fftBatchFn(getCoreUrl(selectedSystemType), queryKey);
            },
            select: function (resp) {
                return resp;
            },
            placeholderData: keepPreviousData,
            enabled: !!installationPointId,
        }); }),
    });
    var data = {};
    results.forEach(function (result) {
        data = _merge(data, result.data);
    });
    return {
        data: data,
        isPending: results.some(function (result) { return result.isPending; }),
        isFetching: results.some(function (result) { return result.isFetching; }),
        isFetched: results.some(function (result) { return result.isFetched; }),
        isLoading: results.some(function (result) { return result.isLoading; }),
    };
};
