var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Input } from 'reactstrap';
import { GROUP_RMS, POINT_TYPE_ADAPTER, READING_TYPE_TEMPERATURE } from '../../../../../constants';
export var SelectTypes = function (_a) {
    var onSelectReadingTypes = _a.onSelectReadingTypes, readingTypes = _a.readingTypes, type = _a.type;
    if (!type) {
        return (_jsx("div", { className: "d-flex title-block", children: _jsxs(Input, { type: "select", value: readingTypes, className: "select-title", onChange: function (ev) { return onSelectReadingTypes(ev.target.value); }, children: [_jsx("option", { value: "", children: "All" }), _jsx("option", { value: [String(READING_TYPE_TEMPERATURE)], children: "Temperature" }), _jsx("option", { value: GROUP_RMS.map(function (el) { return String(el); }), children: "RMS" }), _jsx("option", { value: "fft", children: "FFT" })] }) }));
    }
    if (type === 'rms') {
        return (_jsx("div", { className: "d-flex title-block", children: _jsxs(Input, { type: "select", value: readingTypes, className: "select-title", onChange: function (ev) { return onSelectReadingTypes(ev.target.value); }, children: [_jsx("option", { value: __spreadArray([String(READING_TYPE_TEMPERATURE)], GROUP_RMS.map(function (el) { return String(el); }), true), children: "All" }), _jsx("option", { value: [String(READING_TYPE_TEMPERATURE)], children: "Temperature" }), _jsx("option", { value: GROUP_RMS.map(function (el) { return String(el); }), children: "RMS" }), _jsx("option", { value: POINT_TYPE_ADAPTER, children: "Adapters" })] }) }));
    }
    return (_jsx("div", { className: "d-flex title-block", children: _jsx(Input, { type: "select", value: readingTypes, className: "select-title", onChange: function (ev) { return onSelectReadingTypes(ev.target.value); }, children: _jsx("option", { value: "fft", children: "FFT" }) }) }));
};
