var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { get as _get } from 'lodash';
import React, { memo, useContext, useEffect, useState } from 'react';
import { FaCheckCircle, FaPencilAlt, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { Button, Col, Row } from 'reactstrap';
import Endpoints from '../../../../../api/endpoints/endpoints';
import { ReactComponent as Radiator } from '../../../../../assets/svg/radiator.svg';
import { SYSTEM_TYPE_REMASTERED } from '../../../../../constants';
import { IntervalsContent } from '../../../../../remastered/components/installationPoint/IntervalsContent';
import ImpactVueForm from '../../../../../remastered/modals/installationPoint/ImpactVueForm';
import IntervalsForm from '../../../../../remastered/modals/installationPoint/IntervalsForm';
import { useHeatsinkBatchUpdateMutation, } from '../../../../../remastered/services/heatsink/useHeatsinkBatchUpdateMutation';
import { selectSelectedCustomers } from '../../../../../remastered/store/SelectedCustomersStore';
import { selectSelectedLocations } from '../../../../../remastered/store/SelectedLocationsStore';
import { getHexId } from '../../../../../widgets/sensor';
import DefaultTooltip from '../../../../components/DefaultTooltip';
import Loader from '../../../../components/Loader';
import { SystemTypeContext } from '../../../../context/SystemTypeContext';
import { InstallationPointStore } from '../../../../store/charts/chartsContent/InstallationPointStore';
import { LeftEquipmentListStore } from '../../../../store/charts/leftEquipmentList/LeftEquipmentListStore';
import '../../MachineInfo.scss';
import { fetch } from './features';
export var readingTypeIntervalLabels = {
    2: 'Minutes',
    3: 'Hours',
    4: 'Days',
    5: 'Weeks',
};
var IntervalsTab = function (_a) {
    var equipment_id = _a.equipment_id, sensor = _a.sensor, customerId = _a.customerId, equipmentName = _a.equipmentName;
    var selectedSystemType = useContext(SystemTypeContext)[0];
    var setHeatsinkEquipment = LeftEquipmentListStore(function (state) { return state.setHeatsink; });
    var setHeatsink = InstallationPointStore(function (state) { return state.setHeatsink; });
    var _b = useState([]), list = _b[0], setList = _b[1];
    var _c = useState(undefined), triggers = _c[0], setTriggers = _c[1];
    var _d = useState(true), loader = _d[0], setLoader = _d[1];
    var _e = useState({
        visible: false,
        installationPoint: undefined,
        equipment: undefined,
        triggers: undefined,
        serials: [],
        onCancel: function () {
            setIntervalsFormData(__assign(__assign({}, intervalsFormData), { visible: false, installationPoint: undefined, equipment: undefined, serials: [] }));
            callbackFetch();
        },
    }), intervalsFormData = _e[0], setIntervalsFormData = _e[1];
    var selectedCustomers = selectSelectedCustomers();
    var selectedLocations = selectSelectedLocations();
    var _f = useState({
        visible: false,
        equipment: undefined,
        selectedCustomers: selectedCustomers,
        selectedLocations: selectedLocations,
        onCancel: function () {
            setImpactVueFormData(function (prevState) { return (__assign(__assign({}, prevState), { visible: false, equipment: undefined })); });
        },
    }), impactVueFormData = _f[0], setImpactVueFormData = _f[1];
    var callbackFetch = function () {
        if (!equipment_id) {
            return;
        }
        fetch(intervalsFormData, setIntervalsFormData, setList, setTriggers, setLoader, function () {
            return Endpoints[selectedSystemType].getInstallationPointsByEquipment(+equipment_id);
        });
    };
    useEffect(function () {
        callbackFetch();
    }, []);
    var onEdit = function (installationPoint) {
        setIntervalsFormData(__assign(__assign({}, intervalsFormData), { visible: true, installationPoint: installationPoint, serials: [_get(installationPoint, 'sensor.node.serial', '---')] }));
    };
    var _g = useHeatsinkBatchUpdateMutation(), heatsinkBatchUpdateMutate = _g.mutate, heatsinkBatchUpdateIsLoading = _g.isPending;
    var heatsinkHandler = function (isHeatsink) {
        heatsinkBatchUpdateMutate({
            isHeatsink: !isHeatsink,
            selectedCustomers: customerId ? [customerId] : [],
            selectedLocations: [],
            equipmentId: equipment_id !== null && equipment_id !== void 0 ? equipment_id : 0,
        }, {
            onSuccess: function () {
                toast.success('Successfully changed!', { icon: _jsx(FaCheckCircle, { style: { fill: '#198754' } }) });
                setHeatsink(!isHeatsink);
                setHeatsinkEquipment(!isHeatsink, equipment_id !== null && equipment_id !== void 0 ? equipment_id : 0);
                callbackFetch();
            },
            onError: function () {
                toast.error('Something went wrong!', { icon: _jsx(FaTimes, { style: { fill: '#af2929' } }) });
            },
        });
    };
    return (_jsx("div", { className: "h-100", children: loader ? (_jsx("div", { className: "d-flex justify-content-center align-items-center h-100", children: _jsx(Loader, { variant: "loader-lg" }) })) : (_jsxs("div", { className: "mt-3", children: [_jsx("h4", { children: "Intervals" }), _jsxs("div", { className: "fz-12", children: [_jsxs(Row, { className: "header-block fw-bold p-2", style: { zIndex: 1 }, children: [_jsx(Col, { md: 2, children: "ALL EQUIPMENT" }), _jsx(Col, { md: 1, children: "INTERVALS" }), _jsx(Col, { md: 1, children: "FFT" }), _jsx(Col, { md: 1, children: "FMAX" }), _jsx(Col, { md: 1, children: "LOR" }), _jsx(Col, { md: 1, children: "RMS HIGH-PASS" }), _jsx(Col, { md: 1, children: _jsxs("div", { className: "d-flex align-items-center", children: ["ImpactVue", !list.some(function (_a) {
                                                var sensorParameters = _a.sensorParameters;
                                                return sensorParameters === null || sensorParameters === void 0 ? void 0 : sensorParameters.supportHfdvue;
                                            }) && (_jsx(Button, { title: "Edit ImpactVue state for ".concat(equipmentName, " equipment"), color: "primary", size: "xs", className: "ms-2", onClick: function () {
                                                    if (equipment_id && equipmentName) {
                                                        setImpactVueFormData(function (prevState) { return (__assign(__assign({}, prevState), { equipment: {
                                                                id: equipment_id,
                                                                name: equipmentName,
                                                            }, visible: true })); });
                                                    }
                                                }, children: _jsx(FaPencilAlt, {}) }))] }) }), _jsx(Col, { md: 2, children: "TRIGGERS" }), _jsx(Col, { md: 1, className: "text-end", children: selectedSystemType === SYSTEM_TYPE_REMASTERED ? (_jsxs(_Fragment, { children: [_jsx(Radiator, { "data-tooltip-id": "radiators", onClick: function () { return heatsinkHandler(list.every(function (_a) {
                                                    var is_heatsink = _a.is_heatsink;
                                                    return !!is_heatsink;
                                                })); }, style: {
                                                    width: '18px',
                                                    float: 'right',
                                                    marginRight: '10px',
                                                    cursor: heatsinkBatchUpdateIsLoading ? 'wait' : 'pointer',
                                                    fill: list.every(function (_a) {
                                                        var is_heatsink = _a.is_heatsink;
                                                        return !!is_heatsink;
                                                    }) ? '#2962ff' : '#777',
                                                    opacity: heatsinkBatchUpdateIsLoading ? 0.6 : 1,
                                                } }), _jsx(DefaultTooltip, { id: "radiators", place: "top", children: "Insulator" })] })) : (_jsx(_Fragment, {})) }), _jsx(Col, { md: 1 })] }), list.map(function (item) {
                            var _a;
                            return (_jsx(IntervalsContent, { isSupportImpactVue: (_a = item === null || item === void 0 ? void 0 : item.sensorParameters) === null || _a === void 0 ? void 0 : _a.supportHfdvue, installationPoint: item, triggers: triggers, textToHighlight: getHexId(sensor === null || sensor === void 0 ? void 0 : sensor.sensor_id), onEdit: onEdit, heatsinkCallback: setHeatsink, onUpdateSensorImpactVue: function () {
                                    callbackFetch();
                                } }, "interval_row_".concat(item.id)));
                        })] }), _jsx(IntervalsForm, { intervalsFormData: intervalsFormData, callback: function () { return callbackFetch(); } }), impactVueFormData.visible && (_jsx(ImpactVueForm, { impactVueFormData: impactVueFormData, onSuccess: function () { return callbackFetch(); } }))] })) }));
};
export default memo(IntervalsTab);
