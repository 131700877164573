import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../../api/api';
import { getHost } from '../../../shared/helper/customer';
import { preActionItemKey } from './usePreActionItemQuery';
var setPreAIMLReportScoreFn = function (body, storage) {
    return Api.post("all-analytics/pre-action-list-ml-report/score/".concat(body.id), body, { host: getHost(storage) });
};
export var useSetPreAIMLReportScoreMutation = function (_a) {
    var preActionItemId = _a.preActionItemId, _b = _a.storage, storage = _b === void 0 ? null : _b;
    var queryClient = useQueryClient();
    return useMutation({
        mutationFn: function (body) { return setPreAIMLReportScoreFn(body, storage); },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: preActionItemKey(String(preActionItemId)) });
        },
    });
};
