import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { memo } from 'react';
import CommentToML from '../../AIPreAi/topButtons/CommentToMLBtn';
import RefreshBtn from '../../AIPreAi/topButtons/RefreshBtn';
import RateMlDiagnosisButton from './components/RateMlDiagnosisButton';
var Actions = function (_a) {
    var isShowMlComment = _a.isShowMlComment, onInvalidateData = _a.onInvalidateData, activeMlReport = _a.activeMlReport;
    return (_jsxs("div", { className: "top-actions", children: [_jsx(RefreshBtn, { onInvalidateData: onInvalidateData }), isShowMlComment ? _jsx(CommentToML, {}) : _jsx(_Fragment, {}), !!((activeMlReport === null || activeMlReport === void 0 ? void 0 : activeMlReport.id) && (activeMlReport === null || activeMlReport === void 0 ? void 0 : activeMlReport.short_description) === null) && (_jsx(RateMlDiagnosisButton, { activeMlReport: activeMlReport }))] }));
};
export default memo(Actions);
