import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useState } from 'react';
import { FaEye, FaStar } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Button, ButtonGroup, FormGroup, Input, Label } from 'reactstrap';
import { READING_TYPE_TWF_ACCELERATION_X, SYSTEM_TYPE_REMASTERED } from '../../../../constants';
import { useSetPreAIMLReportScoreMutation, } from '../../../../remastered/services/preActionItems/useSetPreAIMLReportScoreMutation';
import { onViewInstallationPoint } from '../../../features/shared';
import useContentSectionToggle from '../../../hooks/AIPreAi/useContentSectionToggle';
import SectionBtnToggle from '../../AIPreAi/SectionBtnToggle';
import { DEFAULT_DIRECTION } from '../../AIPreAi/SensorInfoBlock/SensorInfoBlock';
import './index.scss';
var SCORES = [1, 2, 3, 4, 5];
export var RateMLDiagnosis = memo(function (_a) {
    var _b;
    var activeMlReport = _a.activeMlReport, localStorageKey = _a.localStorageKey, installationPoint = _a.installationPoint, storage = _a.storage;
    var preActionItemId = useParams().id;
    var _c = useContentSectionToggle(localStorageKey, DEFAULT_DIRECTION), isContentVisible = _c.isContentVisible, storageStateMemory = _c.storageStateMemory, setIsContentVisible = _c.setIsContentVisible, onSaveAsDefaultSize = _c.onSaveAsDefaultSize;
    var id = activeMlReport.id, twfDataTimestamp = activeMlReport.twfDataTimestamp, pre_action_item_id = activeMlReport.pre_action_item_id, mlScore = activeMlReport.score, text = activeMlReport.text, short_description = activeMlReport.short_description, mlComment = activeMlReport.comment;
    var _d = useState((_b = mlScore === null || mlScore === void 0 ? void 0 : mlScore.score) !== null && _b !== void 0 ? _b : null), score = _d[0], setScore = _d[1];
    var _e = useState(mlComment), comment = _e[0], setComment = _e[1];
    var _f = useState(false), isVisibleComment = _f[0], setIsVisibleComment = _f[1];
    var getContent = function (text) {
        return text
            ? text
                .replace(/^\s+|\s+$/g, function (match) { return '<span style="display:inline-block;">' + '&nbsp;'.repeat(match.length) + '</span>'; })
                .replace('STATUS : APPROVED', '<b>STATUS : APPROVED</b>')
                .replace('STATUS : DECLINED', '<b>STATUS : DECLINED</b>')
                .replaceAll('\n', '</br>')
            : '';
    };
    var _g = useSetPreAIMLReportScoreMutation({
        preActionItemId: pre_action_item_id !== null && pre_action_item_id !== void 0 ? pre_action_item_id : preActionItemId,
        storage: storage,
    }), setPreAIMLReportScoreMutate = _g.mutate, setPreAIMLReportScoreIsLoading = _g.isPending;
    var onSave = function (_a) {
        var score = _a.score, comment = _a.comment;
        if (!score || setPreAIMLReportScoreIsLoading || id === 'not set') {
            return;
        }
        setPreAIMLReportScoreMutate({
            id: id,
            comment: comment,
            score: score,
        });
    };
    return (_jsxs("div", { className: "aiPreAi-rate-ml ai-on-equipment default-ai-block", children: [_jsxs("div", { className: "default-ai-block-header", children: [_jsx("div", { className: "default-ai-block-header-title d-flex justify-content-between w-100", children: _jsxs("div", { className: "d-flex align-items-center", children: [twfDataTimestamp ? (_jsx(Button, { onClick: function () {
                                        var _a;
                                        return onViewInstallationPoint((_a = installationPoint === null || installationPoint === void 0 ? void 0 : installationPoint.id) !== null && _a !== void 0 ? _a : 0, SYSTEM_TYPE_REMASTERED, "&fft_date=".concat(activeMlReport.twfDataTimestamp, "&scroll_to=").concat(READING_TYPE_TWF_ACCELERATION_X));
                                    }, size: "sm", className: "me-2", color: "primary", children: _jsx(FaEye, {}) })) : null, "Rate ML Diagnosis:"] }) }), _jsx(SectionBtnToggle, { isContentVisible: isContentVisible, storageStateMemory: storageStateMemory, setIsContentVisible: setIsContentVisible, onSaveAsDefaultSize: onSaveAsDefaultSize, localStorageKey: localStorageKey })] }), _jsxs("div", { className: "default-ai-block-content", children: [id !== 'not set' && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "d-flex align-items-end gap-2 justify-content-end", children: [_jsxs(Button, { onClick: function () { return setIsVisibleComment(function (prev) { return !prev; }); }, size: "sm", className: "me-2", color: "primary", children: [isVisibleComment ? 'Hide' : 'Add', " comment for ML team"] }), _jsxs("span", { children: [score ? 'Assessment of the current report:' : 'Rate report:', " "] }), _jsx("div", { className: "rate d-flex gap-1", children: SCORES.map(function (itemScore) { return (_jsx("span", { onClick: function () {
                                                setScore(itemScore);
                                                if (isVisibleComment) {
                                                    return;
                                                }
                                                onSave({
                                                    score: itemScore,
                                                    comment: mlComment,
                                                });
                                            }, className: score && score >= itemScore ? 'active' : '', children: _jsx(FaStar, {}) }, itemScore)); }) })] }), isVisibleComment && (_jsxs("span", { children: [_jsx("div", { className: "my-2", children: !mlComment ? (_jsxs(FormGroup, { children: [_jsxs(Label, { className: "d-flex justify-content-between", children: ["Comment for ML team: ", _jsx("i", { className: "fz-15", children: "Optional" })] }), _jsx(Input, { style: {
                                                        minHeight: '150px',
                                                    }, type: "textarea", value: comment, onChange: function (ev) { return setComment(ev.target.value); } })] })) : null }), _jsx("div", { className: "mb-2 d-flex justify-content-end", children: _jsx(ButtonGroup, { children: _jsx(Button, { disabled: !score || setPreAIMLReportScoreIsLoading || !comment, className: "fz-13", size: "sm", color: "success", onClick: function () {
                                                    return onSave({
                                                        score: score,
                                                        comment: comment,
                                                    });
                                                }, children: "Rate and save" }) }) })] }))] })), _jsx("div", { className: "h-4 mt-2 mb-2 font-monospace", dangerouslySetInnerHTML: {
                            __html: getContent(isContentVisible ? text : short_description ? short_description : text.slice(0, 800)),
                        } })] })] }));
});
RateMLDiagnosis.displayName = 'RateMLDiagnosis';
